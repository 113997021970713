* {
  image-rendering: pixelated;
}

#couch {
  background: url("couch-backdrop.e608b27d.png");
  justify-content: center;
  align-items: center;
  width: 472px;
  height: 41px;
  display: flex;
  position: absolute;
  top: 780px;
}

#couch #couch-icon {
  background: url("icon-couch.6b58a031.png");
  width: 42px;
  height: 22px;
  margin-top: 3px;
}

#couch .couch-names {
  text-transform: uppercase;
  color: #e2881d;
  text-shadow: 0 0 8px #e2881d00;
  margin-top: 4px;
  margin-left: 10px;
  font-size: 24px;
}
/*# sourceMappingURL=repo.3e6fcd9b.css.map */
